import React from "react";
import { PageProps } from "gatsby";
import { Page } from "@/components/Page";
import { Login } from "@/features/Authentication/Login";
import { withoutAuthentication } from "@/features/Authentication/AccessRestriction";

interface LoginPageLocationState {
  email?: string;
}

const LoginPage = ({
  location: { state },
}: PageProps<unknown, unknown, LoginPageLocationState | undefined>) => {
  const email = state?.email;

  return (
    <Page variation="presession">
      <Login defaultEmail={email} />
    </Page>
  );
};

export default withoutAuthentication(LoginPage);
